<script lang="ts">
    import { Button } from '@repo/components/basic';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let title = '';
    export let msg = '';
    export let useHtmlMsg = true;
    export let color: 'dark' | 'gray' | 'green' | 'red' = 'dark';
    export let onConfirm: (v: any) => void = () => true;
    export let confirmText = '확인';

    function handleConfirm() {
        dispatch('confirm', true);
        onConfirm?.(true);
    }

    // let modalBodyHeight = 0;
    // export let modalBodyMaxHeight = 544;
</script>

<div class="mt-10 w-full text-center" style="max-height: calc(100% - 2.5rem);">
    <div class="flex flex-col gap-2 overflow-y-auto overscroll-contain px-6 py-10" style="max-height: calc(100% - 4.5rem)" role="document" on:wheel|stopPropagation|passive>
        {#if title}
            <h2 class="whitespace-pre-wrap text-xl font-bold text-gray-900">
                {title}
            </h2>
        {/if}
        {#if msg}
            <h3 class="whitespace-pre-wrap text-base font-normal text-gray-700 dark:text-gray-400">
                {#if useHtmlMsg}
                    {@html msg}
                {:else}
                    {msg}
                {/if}
            </h3>
        {/if}
    </div>
    <section class="flex items-center justify-end gap-2 rounded-b-lg p-6 pt-0">
        <Button {color} size="xl" class="flex-1" on:click={handleConfirm}>
            <slot name="confirm">
                {@html confirmText}
            </slot>
        </Button>
    </section>
</div>

<!-- 
@component
## Features
    - alert modal
    - title
    - msg
    - color
    - onConfirm

## Props
    @prop title: string = '';
    @prop msg: string = '';
    @prop useHtmlMsg: boolean = true;
    @prop color: 'dark' | 'gray' | 'green' | 'red' = 'dark';
    @prop onConfirm: (v: any) => void = () => true;
    @prop confirmText: string = '확인';

## Example
    ```
        <Button on:click={() => {defaultModal = true; returnValue='';}}>Modal</Button>
        <Modal bind:open={defaultModal} size="sm" {...args}>
            <Alert
                title="Alert"
                msg="이것은 Alert Message 입니다" 
                on:confirm={e => {returnValue = e.detail;}}
            />
        </Modal>
        ---
        <Button 
            on:click={async() => {
                returnValue = await alert('이것은 alert 입니다');
            }}
        >
            Alert modal
        </Button>
    ```
-->
