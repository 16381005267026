<script lang="ts">
    import { Button, Input, Label } from '@repo/components/basic';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let msg = '';
    export let useHtmlMsg = true;
    export let color: 'dark' | 'gray' | 'green' | 'red' = 'dark';
    export let onConfirm: (v: any) => void = () => value;
    export let confirmText = '확인';
    export let onCancel: () => void = () => {};
    export let cancelText = '취소';
    export let reverseButton = false;
    export let validation: (value: any) => boolean = () => true;
    export let inputProps: any = null; // props for Input Component
    export let labelText = '';

    export let value: any = '';
    let validationPassed = true;
    $: value, (validationPassed = validation(value));

    function handleConfirm() {
        dispatch('confirm', value);
        onConfirm?.(value);
    }

    function handleCancel() {
        dispatch('cancel');
        onCancel?.();
    }
</script>

<div class="mt-10 w-full text-center" style="max-height: calc(100% - 2.5rem);">
    <div class="flex flex-col gap-2 overflow-y-auto overscroll-contain px-6 py-10" style="max-height: calc(100% - 4.5rem);" role="document" on:wheel|stopPropagation|passive>
        <!-- text -->
        {#if msg}
            <h3 class="whitespace-pre-wrap text-base font-normal text-gray-700 dark:text-gray-400">
                {#if useHtmlMsg}
                    {@html msg}
                {:else}
                    {msg}
                {/if}
            </h3>
        {/if}

        <div class="flex w-full flex-col items-start">
            {#if labelText}
                <Label color="gray" class="mb-2 block">
                    <slot name="label">{@html labelText}</slot>
                </Label>
            {/if}
            <Input {...inputProps} bind:value />
        </div>
    </div>
    <section class="flex items-center justify-end gap-2 rounded-b-lg p-6 pt-0 {reverseButton ? 'flex-row-reverse' : ''}">
        <Button {color} class="flex flex-1" on:click={handleConfirm} disabled={!validationPassed}>
            <slot name="confirm">
                {@html confirmText}
            </slot>
        </Button>
        <Button color="dark" class="flex flex-1" outline on:click={handleCancel}>
            <slot name="cancel">
                {@html cancelText}
            </slot>
        </Button>
    </section>
</div>

<!-- 
@component
## Features
    - prompt modal
    - msg
    - color
    - input binding
    - onConfirm
    - onCancel

## Props
    @prop msg: string = '';
    @prop useHtmlMsg: boolean = true;
    @prop color: 'dark' | 'gray' | 'green' | 'red' = 'dark';
    @prop onConfirm: (v: any) => void = () => value;
    @prop confirmText: string = '확인';
    @prop onCancel: () => void = () => {};
    @prop cancelText: string = '취소';
    @prop reverseButton: boolean = false;
    @prop value: any = '';
    @prop validation: (value: any) => boolean = () => true;
    @prop inputProps: any = null;
    @prop labelText: string = '';
    @prop modalBodyMaxHeight: number = 544;

## Example
    ```
        <Button on:click={() => {defaultModal = true; returnValue = false;}}>Modal</Button>
        <Modal bind:open={defaultModal} size="sm" {...args}>
            <Prompt
                msg="이것은 Prompt Message 입니다"
                on:confirm={e => {returnValue = e;}}
                on:cancel={e => {returnValue = false;}}
                inputProps={{ placeholder: '입력하세요' }}
            />
        </Modal>
        ---
        <Button on:click={async() => {
            returnValue = await prompt('이것은 prompt 입니다');
        }}>
            Prompt modal
        </Button>
    ```
-->
