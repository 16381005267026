<script lang="ts">
    import { Button } from '@repo/components/basic';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let title = '';
    export let msg = '';
    export let useHtmlMsg = true;
    export let color: 'dark' | 'gray' | 'green' | 'red' = 'dark';
    export let onConfirm: (v: any) => void = () => true;
    export let confirmText = '확인';
    export let onCancel: () => void = () => false;
    export let cancelText = '취소';
    export let reverseButton = false;

    function handleConfirm() {
        dispatch('confirm', true);
        onConfirm?.(true);
    }

    function handleCancel() {
        dispatch('cancel');
        onCancel?.();
    }
</script>

<div class="mt-10 w-full text-center" style="max-height: calc(100% - 2.5rem);">
    <div class="flex flex-col gap-2 overflow-y-auto overscroll-contain px-6 py-10" style="max-height: calc(100% - 4.5rem)" role="document" on:wheel|stopPropagation|passive>
        {#if title}
            <h2 class="whitespace-pre-wrap text-xl font-bold text-gray-900">
                {title}
            </h2>
        {/if}
        {#if msg}
            <h3 class="whitespace-pre-wrap text-base font-normal text-gray-700 dark:text-gray-400">
                {#if useHtmlMsg}
                    {@html msg}
                {:else}
                    {msg}
                {/if}
            </h3>
        {/if}
    </div>
    <section class="flex items-center justify-end gap-2 rounded-b-lg p-6 pt-0 {reverseButton ? 'flex-row-reverse' : ''}">
        <Button {color} class="flex flex-1" on:click={handleConfirm}>
            <slot name="confirm">
                {@html confirmText}
            </slot>
        </Button>
        <Button color="dark" class="flex flex-1" outline on:click={handleCancel}>
            <slot name="cancel">
                {@html cancelText}
            </slot>
        </Button>
    </section>
</div>

<!-- 
    @component
    ## Features
    - confirm modal
    - title
    - msg
    - color
    - onConfirm
    - onCancel
    - reverseButton


    ## Props
    @prop title: string = '';
    @prop msg: string = '';
    @prop useHtmlMsg: boolean = true;
    @prop color: 'dark' | 'gray' | 'green' | 'red' = 'dark';
    @prop onConfirm: (v: any) => void = () => {};
    @prop onCancel: () => void = () => {};
    @prop reverseButton: boolean = false;
    @prop confirmText: string = '확인';
    @prop cancelText: string = '취소';

    ## Example
    ```
        <Button on:click={() => defaultModal = true}>Modal</Button>
        <Modal bind:open={defaultModal} size="sm" {...args}
        >
            <Confirm
                title="Confirm"
                msg="이것은 Confirm Message 입니다"
                on:confirm={e => {returnValue = e.detail;}}
                on:cancel={e => {returnValue = false;}}
            />
        </Modal>
        ---
        <Button 
            on:click={async() => {
                returnValue = await confirm('이것은 confirm 입니다')
            }}
        >
            Confirm modal
        </Button>
    ```

-->
